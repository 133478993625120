/*
 ** MENU FUNCTIONS
 */

const menuActiveClass = 'menu-active';
const countryActiveClass = 'country-active';
const countryArea = document.querySelector('.nav-holder');
const countryOpener = document.querySelector('.country-opener');
const dynamicRadioStations = document.getElementById('dynamic-radio-stations')
const menuArea = document.querySelector('.menu-area');
const menuOpener = document.querySelector('.menu-opener');
const body = document.documentElement;
let menuState;

if (dynamicRadioStations) {
  const isFavoritePage = !!document.querySelector('[data-station-list="myFavorites"]');
  const isRecentlyPLayedPage = !!document.querySelector('[data-station-list="recentlyPlayed"]');
  let stations = [];

  if (isFavoritePage) {
    stations = (JSON.parse(localStorage.getItem('myFavorites')) || []).slice(0, 48);
  } else if (isRecentlyPLayedPage) {
    stations = (JSON.parse(localStorage.getItem('recentlyPlayed'))|| []).slice(0, 48);
  }

  if (stations.length > 0) {
    dynamicRadioStations.innerHTML = '';
    for (let i = 0; i < stations.length; i++) {
      const station = stations[i];
      const element = document.createElement('li');
      element.innerHTML = `<a href="${window.location.origin}/${station.station_name}/">${station.station_title}</a>`;
      dynamicRadioStations.appendChild(element);
    }
  }
}

if (menuOpener && countryOpener) {
  document.documentElement.addEventListener('click', closeMenu);
  menuOpener.addEventListener('click', (e) => toggleMenu(e, menuActiveClass));
  countryOpener.addEventListener('click', (e) => toggleMenu(e, countryActiveClass));
}

function toggleMenu(e, className) {
  e.preventDefault();
  e.stopPropagation();

  if (menuState) {
    if (menuState === className) {
      document.body.classList.remove(className);
      menuState = null
    } else {
      const otherClass = [menuActiveClass, countryActiveClass].filter(cn => cn !== className)
      document.body.classList.remove(otherClass);
      document.body.classList.add(className);
      menuState = className;
    }
  } else {
    document.body.classList.toggle(className);
    menuState = className;
  }
}

function closeMenu(e) {
  if (menuState) {
    // check if it is one of the menu rappers
    if (
      menuArea === e.target
      || menuArea.contains(e.target)
      || (countryArea === e.target)
      || countryArea.contains(e.target)
    ) {
      return;
    }

    document.body.classList.remove(menuActiveClass);
    document.body.classList.remove(countryActiveClass);
    menuState = null;
  }
}
