/*
 ** DETECT FUNCTIONS
 */

// Detect Media Source Extensions API (https://github.com/Modernizr/Modernizr/pull/2233)
window.hasMSE = ( function() {
	if ( "mediasource", "MediaSource" in window ) {
		return true;
	} else {
		return false;
	}
}());
