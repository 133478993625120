import cookie from '../../lib/cookie';

const cookieConsentElement = document.querySelector('.cookie-consent');
const acceptButton = document.querySelector('.cookie-consent__button');
const ACTIVE_CLASS = 'is-visible';

function init() {
  if (!cookieConsentElement) {
    return;
  }

  if (!cookie('cookie-consent-seen')) {
    showCookieConsent();
  }

  acceptButton.addEventListener('click', onAccept);
}

init()

function onAccept(e) {
  e.preventDefault();
  cookie('cookie-consent-seen', true, 365);
  hideCookieConsent();
}

export function showCookieConsent() {
  cookieConsentElement.removeAttribute('aria-hidden');
  cookieConsentElement.classList.add(ACTIVE_CLASS);
  cookieConsentElement.setAttribute('role', 'alert');
  cookieConsentElement.setAttribute('aria-live', 'assertive');
}

export function hideCookieConsent() {
  cookieConsentElement.setAttribute('aria-hidden', 'true');
  cookieConsentElement.classList.remove(ACTIVE_CLASS);
  cookieConsentElement.removeAttribute('role');
  cookieConsentElement.removeAttribute('aria-live');
}
