/*
 ** ERROR FUNCTIONS
 */

const noRadioElement = document.getElementById('radio-no-audio');

if (noRadioElement) {
  noRadioElement.addEventListener('click', () => {
    if (typeof webradioConfig.stream === "object") {

      // Log stream error
      fetch(webradioConfig.urlApi + "data/error", {
        method: 'POST',
        body: JSON.stringify({stream_id: webradioConfig.stream.id})
      })
    }
  })
}
