/*
 ** VOTE FUNCTIONS
 */


const voteTriggers = document.querySelectorAll('#votes a.vote');

if (voteTriggers.length) {
  Array.prototype.forEach.call(voteTriggers, (trigger) => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();

      // Check if the station is defined
      if ( typeof webradioConfig.station === "object" ) {

        // Check if there has been voted already
        if ( webradioConfig.station.voted === 1 ) {
          return;
        } else {
          const $vote = e.currentTarget.querySelector('span')

          // Post station vote
          fetch(webradioConfig.urlApi + "data/vote", {
            method: 'POST',
            body: JSON.stringify({
              station_id: webradioConfig.station.id,
              vote: $vote.dataset.vote
            })
          })
            .then(() => {
              // Change number of station votes for vote element
              $vote.innerText = ( ( parseInt( $vote.innerText, 10 ) || 0 ) + 1 );

              // Set as voted
              webradioConfig.station.voted = 1;
            })
        }
      }
    })
  })
}
