const API_REQUEST_FREQUENCY = 60; // Value should be in seconds.

const playlist = document.querySelector('[data-playlist]');
const playlistCurrentSong = document.querySelector('[data-playlist-current-song]');
const playlistPreviousSongs = document.querySelector('[data-playlist-previous-songs]');

if (playlist && playlistCurrentSong && playlistPreviousSongs) {
  function render(data) {
    if (data && data.length) {
      renderPlaylist(data);
    }
  }

  // Fetch the playlist data from the API.
  fetchPlaylist().then(data => render(data));

  setInterval(function() {
    fetchPlaylist().then(data => render(data));
  }, API_REQUEST_FREQUENCY * 1000);
}

function fetchPlaylist() {
  const url = webradioConfig.urlApi + 'data/playlist/' + webradioConfig.domainID + '/' + getStationName();

  return fetch(url, {cache: 'no-cache'})
    .then(res => res.json())
    .then(data => data.result)
}

function getStationLogo() {
  return webradioConfig.urlStatic + 'images/logos/' + webradioConfig.slugDomain + '/' + getStationName() + '.png';
}

function getStationName() {
  return document.getElementById( 'radio' ).dataset.stationName; 
}

function getTitle(data) {
  if (data.track_artist && data.track_title) {
    return data.track_artist + ' - ' + data.track_title;
  }

  return data.track_artist ?? '' + data.track_title ?? '';
}

function renderPlaylist(data) {
  const itemsToRender = [];

  if (data.length > 1) {
    playlistPreviousSongs.style.display = 'block';

    for (let i = 1; i < data.length; i++) {
      itemsToRender.push(
        '<li class="playlist__item" title="' + getTitle(data[i]) + '">' +
          '<img class="playlist__img" src="' + (data[i].track_image ? data[i].track_image : getStationLogo()) + '" alt="' + getTitle(data[i]) + '" height="42" importance="low" width="42">' +
          '<p class="playlist__metadata">' +
            '<span class="playlist__song-name">' + data[i].track_title + '</span>' +
            '<span class="playlist__artist-name">' + data[i].track_artist + '</span>' +
          '</p>' +
          '<time class="playlist__time">' + timeSince(new Date(data[i - 1].track_played * 1000)) + '</time>' +
        '</li>'
      )
    }
  } else {
    playlistPreviousSongs.style.display = 'none';
  }

  renderCurrentSong(data[0]);
  playlistPreviousSongs.innerHTML = itemsToRender.join('');
}

function renderCurrentSong(data) {
  playlistCurrentSong.setAttribute('title', getTitle(data));
  playlistCurrentSong.innerHTML = (
    '<img class="playlist__img" src="' + (data.track_image ? data.track_image : getStationLogo()) + '" alt="' + getTitle(data) + '" height="42" importance="low" width="42">' +
    '<p class="playlist__metadata">' +
      '<span class="playlist__song-name">' + data.track_title + '</span>' +
      '<span class="playlist__artist-name">' + data.track_artist + '</span>' +
    '</p>' +
    `<span class="playlist__time">${webradioConfig.txt.live}</span>`
  )
}

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);
  let intervalType;

  if (interval >= 1) {
    intervalType = 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = 'hour';
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = 'minute';
          } else {
            interval = seconds;
            intervalType = 'second';
          }
        }
      }
    }
  }

  if ('RelativeTimeFormat' in window.Intl) {
    const rtf = new Intl.RelativeTimeFormat(webradioConfig.languageCode + '-' + webradioConfig.countryCode, { style: 'long' });
    return rtf.format(interval * -1, intervalType)
  } else {
    if (interval > 1 || interval === 0) {
      intervalType += 's';
    }
    return interval + ' ' + intervalType + ' ago';
  }
}
