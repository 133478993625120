export function emitEvent(eventName, element) {
  let event
  const el = element ? element : window
  if (typeof window.CustomEvent === 'function') {
    event = new CustomEvent(eventName, {
      bubbles: true
    })
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, true, false, null)
  }

  el.dispatchEvent(event)
}
