/*
 ** TOGGLE FUNCTIONS
 */

// Toggle menu sections
const menuSectionToggles = document.querySelectorAll('#content .menu-area .box');
Array.prototype.forEach.call(menuSectionToggles, initSlide);

// Toggle continents on Radiowebsites.org
const countrySectionToggles = document.querySelectorAll('#content-area .country-section .box');
let areCountrySectionTogglesInitialized = false;

// Toggle station content on detail page
const stationContentToggles = document.querySelectorAll('#content-area .open-close');
Array.prototype.forEach.call(stationContentToggles, initSlide);

function initSlide(el) {
  const toggle = el.querySelector('.opener');
  const slideArea = el.querySelector('.slide');

  if (!areCountrySectionTogglesInitialized) {
    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      slideArea.classList.toggle('js-slide-hidden');
      el.classList.toggle('active');
    });
  }
}

if (countrySectionToggles.length) {
  const testElement = countrySectionToggles[0];
  const toggle = testElement.querySelector('.opener');
  const isMobile = getComputedStyle(toggle)['pointer-events'] !== 'none';

  if (isMobile && !areCountrySectionTogglesInitialized) {
    Array.prototype.forEach.call(countrySectionToggles, initSlide);
    areCountrySectionTogglesInitialized = true;
  }

  window.addEventListener('resize', () => {
    const isMobile = getComputedStyle(toggle)['pointer-events'] !== 'none';

    if (isMobile) {
      Array.prototype.forEach.call(countrySectionToggles, initSlide);
      areCountrySectionTogglesInitialized = true;
    }
  });
}
