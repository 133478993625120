/*
 ** MEDIA FUNCTIONS
 */


// Get media
export function getMedia() {
	var media = {};

	// Web mobile
	if ( bowser.mobile || bowser.tablet ) {

		// Web mobile Android
		if ( bowser.android ) {
			media = {
				"MP3":			[ "",	false	],
				"AAC":			[ "",	false	],
				"OGG":			[ "",	false	],
				"WebM":			[ "",	false	],
				"HLS":			[ ( typeof hasMSE === "boolean" && hasMSE ? true : "" ), "" ], // Native support on most Android browsers, otherwise MSE support required
				"MPEG-DASH":	[ ( typeof hasMSE === "boolean" && hasMSE ? true : "" ), "" ], // MSE support required
				"HTML":			[ true,	""		]
			};

		// Web mobile iOS
		} else if ( bowser.ios ) {
			media = {
				"HLS":			[ true,	""		], // Native support, prefer HLS above MP3 and AAC due HTTP/0.9 restrictions on non-default ports
				"MP3":			[ true, false	],
				"AAC":			[ true, false	],
				// No Ogg Vorbis support
				// No WebM support
				// No MPEG-DASH support due no MSE support
				"HTML":			[ true,	""		]
			};

		// Web mobile generic
		} else {
			media = {
				"MP3":			[ "",	false	],
				// No AAC support
				// No Ogg Vorbis support
				// No WebM support
				// No HLS support
				// No MPEG-DASH support
				"HTML":			[ true,	""		]
			};
		}

	// Web desktop
	} else {

		// Web desktop Chrome or Firefox
		if ( bowser.chrome || bowser.firefox ) {
			media = {
				"MP3":			[ "",	false	],
				"AAC":			[ "",	false	],
				"OGG":			[ "",	false	],
				"WebM":			[ "",	false	],
				"HLS":			[ ( typeof hasMSE === "boolean" && hasMSE ? true : "" ), "" ], // MSE support required
				"MPEG-DASH":	[ ( typeof hasMSE === "boolean" && hasMSE ? true : "" ), "" ], // MSE support required
				"HTML":			[ true,	""		]
			};

		// Web desktop Edge
		} else if ( bowser.msedge ) {
			media = {
				"MP3":			[ "",	false	],
				"AAC":			[ "",	false	],
				"OGG":			[ "",	false ],
				"WebM":			[ "",	false	],
				"HLS":			[ true,	""		], // Native support
				"MPEG-DASH":	[ true,	""		], // Native support
				"HTML":			[ true,	""		]
			};

		// Web desktop Safari
		} else if ( bowser.safari ) {
			media = {
				"HLS":			[ true,	""		], // Native support, prefer HLS above MP3 and AAC due HTTP/0.9 restrictions on non-default ports
				"MP3":			[ "",	false	],
				"AAC":			[ "",	false	],
				// No Ogg Vorbis support
				// No WebM support
				"MPEG-DASH":	[ ( typeof( hasMSE ) === "boolean" && hasMSE ? true : "" ), "" ], // MSE support required
				"HTML":			[ true,	""		]
			};

		// Web desktop generic
		} else {
			media = {
				"MP3":			[ "",	false	],
				// No AAC support
				// No Ogg Vorbis support
				// No WebM support
				"HLS":			[ ( typeof( hasMSE ) === "boolean" && hasMSE ? true : "" ), "" ], // MSE support required
				"MPEG-DASH":	[ ( typeof( hasMSE ) === "boolean" && hasMSE ? true : "" ), "" ], // MSE support required
				"HTML":			[ true,	""		]
			};
		}
	}

	return media;
}
