import { initRadio } from '../radio/radio';

const stationList = document.querySelector('[data-station-list]');
const radioFeedback = document.querySelector('[data-radio-feedback]');

if (stationList) {
  const listId = stationList.getAttribute('data-station-list')
  renderStationList(listId);
  initRadio()
}

export function renderStationList(listId) {
  const stations = JSON.parse(localStorage.getItem(listId)) || [];
  const baseUrl = window.location.href.split( '#' ).shift();
  const itemsToRender = Object.keys(stations).length
    ? stations.map(function (station, index) {
      return (
        `
        <li class="item-${index + 1}">
          <span>
            <a href="${baseUrl}#${station.station_name}" title="${station.station_title}">
              <img class="cover" src="${webradioConfig.urlStatic}images/logos/${webradioConfig.slugDomain}/${station.station_name}.png" alt="${station.station_title}" height="66" width="96">
            </a>
          </span>
        </li>
        `
      )})
    : [];

  while (itemsToRender.length !== 48) {
    itemsToRender.push(
      `<li class="item-${itemsToRender.length + 1} hidden">
        <span>
          <img class="img" src="${webradioConfig.urlStatic}images/radio.png" alt="" height="66" width="96">
        </span>
      </li>`
    );
  }

  if (!stations.length) {
    radioFeedback.classList.add('show');
  }

  stationList.innerHTML = itemsToRender.join('');
}
