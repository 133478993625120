// import { renderFavorites } from '../render-favorites/render-favorites';
import { renderStationList } from '../render-station-list/render-station-list';

const favoriteButton = document.querySelector('[data-favorite-button]');

export function initializeFavoriteButton(currentStation) {
  const userFavorites = JSON.parse(localStorage.getItem('myFavorites')) || [];
  userFavorites.find(station => station.station_id === currentStation.station_id)
    ? favoriteButton.classList.add('favorite-button--active')
    : favoriteButton.classList.remove('favorite-button--active');

  favoriteButton.disabled = false;
  favoriteButton.addEventListener('click', function () {
    handleFavorite(userFavorites, currentStation);
  });
}

function handleFavorite(userFavorites, currentStation) {
  const favoriteIndex = userFavorites.findIndex(station => station.station_id === currentStation.station_id);

  if (favoriteIndex > -1) {
    favoriteButton.classList.remove('favorite-button--active');
    userFavorites.splice(favoriteIndex, 1)
    localStorage.setItem('myFavorites', JSON.stringify(userFavorites));
  } else {
    favoriteButton.classList.add('favorite-button--active');
    userFavorites.push(currentStation);
    localStorage.setItem('myFavorites', JSON.stringify(userFavorites));
  }

  if (window.location.pathname.replace(/\//g, '') === webradioConfig.slugFavorites) {
    renderStationList('myFavorites');
  }
}
