/*
 ** RADIO FUNCTIONS
 */

import { getPlayer } from '../player/player'

var $radioBox = document.getElementById( 'radio' );

export function initRadio() {
  // Radio box set?
  if ( !$radioBox ) {
    return;
  }

  // Station name set?
  let station_name = $radioBox.dataset.stationName;

  var $stations = document.querySelectorAll( '#radios a' );
  if (!station_name && $stations.length ) {
    // Station category page?
    var set_station_data = true;

    var station_name_first= $stations[0].getAttribute( 'href' ).split( '#' ).pop();
    var skip_onhashchange	= false;

    // Get station name from window name, otherwise from hash or first station from grid
    station_name = window.name || parent.location.hash.substr( 1 ) || station_name_first;

    // Reset the window name, or it will persist throughout pages
    window.name ='';

    // Set station name as hash if the selected station is not the first station
    if ( station_name !== station_name_first ) {

      // Add station name as hash to the URL
      try {
        history.replaceState( null, null, '#' + station_name );
      } catch( e ) {
        window.location.hash = station_name;

        // Skip onhashchange to prevent endless loop
        skip_onhashchange = true;
      }
    }

    // Style active station grid logo on init
    const activeStation = Array.prototype.find.call($stations, (station) => station.getAttribute('href') === window.location.href.split( '#' ).shift() + '#' + station_name);
    if (activeStation) {
      activeStation.closest('span').style.cssText = 'border: 1px solid #00a0ce;'
    }

    // Load player on grid logo click
    Array.prototype.forEach.call($stations, (station) => {
      station.addEventListener('click', onStationClick)
    })

    function onStationClick(e) {
      e.preventDefault();

      // Set the station name using the window name
      window.name = e.target.getAttribute( 'href' ).split( '#' ).pop();

      // Reload page without the hash (will be set later)
      window.location.href = window.location.href.split( '#' ).shift();
    }

    // Load player on manual hash change by using the hash change event-listener to reload the page
    window.addEventListener( 'hashchange', function() {

      // Skip event?
      if ( ! skip_onhashchange ) {
        // Set the station name using the window name
        window.name = window.location.href.split( '#' ).pop();

        // Reload page without the hash (will be set later)
        window.location.href = window.location.href.split( '#' ).shift();
      } else {
        skip_onhashchange = false;
      }
    }, false );
  }

  if (station_name) {
    getPlayer(
      station_name,
      set_station_data
    );
  }
}

initRadio();
